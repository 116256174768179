const actions = {
  async getPaymentMethods({ commit }, params) {
    const res = await this.$repositories.payment.getPaymentMethods(params)
    console.log('getPaymentMethods', res.data.data)
    commit('setPayment', res.data.data)
    return res.data.data
  },
  async updatePayment({ commit }, params) {
    const res = await this.$repositories.payment.updatePaymentMethods(params)
    commit('setPayment', res.data.data)
    return res.data.data
  },
  async addDiscountCode({ commit }, params) {
    const res = await this.$repositories.payment.addDiscountCode(params)
    commit('setPayment', res.data.data)
    return res.data.data
  },
  async pay({ commit }, params) {
    const res = await this.$repositories.payment.pay(params)

    return res.data.data
  },
  async createPayPalOrder({ commit }, params) {
    const res = await this.$repositories.payment.createPayPalOrder(params)

    return res.data.data
  },
  async getMyCreditCards({ commit }, params) {
    const res = await this.$repositories.payment.getMyCreditCards(params)

    return res.data.data
  },
  async paymentStatus({ commit }, params) {
    const res = await this.$repositories.payment.paymentStatus(params)

    return res.data.data
  },
  async freePayment({ commit }, params) {
    const res = await this.$repositories.payment.freePayment(params)

    return res.data.data
  },
}
export default actions
