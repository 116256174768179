
export default {
  props: {
    navData: {
      type: Array,
      default: () => [],
    },
    extraNavData: {
      type: Array,
      default: () => [],
    },
    miniVariant: {
      type: Boolean,
      default: false,
    },
    clipped: {
      type: Boolean,
      default: true,
    },
  },
  methods:{
    openUrl(url){
      console.log(url)
      window.open(url,'_self')
    }
  }
}
