const state = () => ({
  payment: null,
  // TODO replace this for a cleaner way. Interceptor?

  paymentConfig: {
    omisePublicKey: 'pkey_test_5n030fxo9ozoje1vydj',
    payPalClient:
      'AcNO26HXTzjYl4_2NISXWnPJ5hZ55JCkGBrtoIZ5z8PZ9UZbIqs9OwHrOt1MwVIuR0ZS0GRBQ7-QOLry',
    payPalMode: 'sandbox',
  },
  paymentMethods: {
    creditCard: 1,
    atm: 2,
    internetBanking: 3,
    webpay: 4,
    payPal: 5,
    counterPay: 6,
    digitalPayment: 6,
  },
  banks: {
    KBANK: 1,
    SCB: 2,
    BBL: 3,
    KTB: 4,
    BAY: 5,
    GSB: 6,
    bigC: 9,
    boonTerm: 10,
    cenPayByCentral: 11,
    payAtPost: 12,
    tescoLotus: 13,
    trueMoney: 14,
    alipay: 15,
    wechatPay: 16,
    linePay: 17,
  },
})
export default state
