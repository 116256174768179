const actions = {

  async getUserForChat({ commit }, params) {
    const res = await this.$repositories.chat.getUserForChat(params)

    return res.data.data
  },
  async sendMessage({ commit }, params) {
    const res = await this.$repositories.chat.sendMessage(params)

    return res.data.data
  },
  async getListOfChat({ commit }, params) {
    const res = await this.$repositories.chat.getListOfChat(params)
    return res.data
  },
  async getChatUserInfo({ commit }, params) {
    const res = await this.$repositories.chat.getChatUserInfo(params)
    return res.data
  },
  async getChat({ commit }, params) {
    const res = await this.$repositories.chat.getChat(params)
    return res.data
  },
  async createChat({ commit }, params) {
    const res = await this.$repositories.chat.createChat(params)
    return res.data
  },
}

export default actions
