export default {
  actions: {
    async nuxtServerInit(
      { dispatch, state },
      { $vuetify, route, $auth, $repositories, redirect }
    ) {
      // await console.log(this.$auth)

      // await setTimeout(async () => {
      //   console.log( $repositories )
      //   const res = await $repositories.testEndPoint.makeTestCall()
      //   console.log(res.data)
      // }, 100);

      //   // fetch taxonomy when site loaded -- init -- uncomment

      await dispatch('taxonomy/getTaxonomy').then(res => {
        // console.log('taxonomy loaded ====>', res)
      })
      // if ($auth.loggedIn) {
      //   if ($auth.user.isFirstTimeLogin) {
      //     redirect('/success-register')
      //   }
      //   // if ($auth.user.package.subscription && !$auth.user.subDomain) {
      //   //   redirect('/success-register?')
      //   // }
      //   await dispatch('user/getUserProfile').then(res => {
      //     console.log('getUserProfile ====>', res)
      //   })
      // }
      await dispatch('config/site/getSiteConfig').then(res => {
        console.log('getSiteConfig ====>', res)

        $vuetify.theme.themes.light.primary = res.primaryColor
        $vuetify.theme.themes.light.secondary = res.secondaryColor
        $vuetify.theme.themes.light.success = res.successColor
        $vuetify.theme.themes.light.error = res.errorColor
        $vuetify.theme.themes.light.availableColor = res.availableColor
        $vuetify.theme.themes.light.comingSoonColor = res.comingSoonColor
        $vuetify.theme.themes.light.rentedColor = res.rentedColor
      }).catch(err=>{
        console.log("getSiteConfig",err)
      })
      //   // Get user profile
      // if ($auth.loggedIn && route.path !== '/callback') {
      // await dispatch('user/getUserProfile')
      // }
      //   // Check if currency is localy saved and change it
      //   if ($auth.$storage.getUniversal('currency')) {
      //     dispatch(
      //       'config/locale/setCurrency',
      //       $auth.$storage.getUniversal('currency')
      //     )
      //   }
      //   if (route.query.currency) {
      //     // Check if there is a currency code in query and change it
      //     dispatch('config/locale/setCurrency', route.query.currency)
      //   }
    },
  },
}
