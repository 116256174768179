
import { mapActions } from 'vuex'
export default {
   props:{
    emailP:{
      type:String,
      default:null
    }
  }, 
  data() {
    return {
      email: null,
      emailRules: [
        v => !!v || this.$t('E-mail is required'),
        v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
      ],
    }
  },
  mounted() {
    if(this.emailP){
      this.email=this.emailP
    }
  },
  methods: {
    ...mapActions('authentication', ['forgetPasword']),
    ...mapActions('config/site', ['setErrorSnackbar']),
    goBack() {
      this.$emit('showLogin', false)
    },
    sendForgetPassword() {
      this.forgetPasword({ email: this.email })
        .then(data => {
          console.log(data)
          this.setErrorSnackbar({
            title: this.$t('Password link sent!'),
            msg: this.$t('Successful!'),
            color: this.$vuetify.theme.themes.light.success,
          })
          this.goBack()
        })
        .catch(er => {
          this.setErrorSnackbar({
            title: this.$t('Error!'),
            msg: er.response.data.message,
            color: this.$vuetify.theme.themes.light.error,
          })
        })
    },
  },
}
