const resetState = () => ({
    navMenus:[
      {
        url: '/home',
        name: 'Home',
      },
     
      {
        url: '/recommend',
        name: 'Recommend',
      },
      {
        url: '/pricing',
        name: 'Pricing',
      },
      {
        url: '/contact',
        name: 'Contact Us',
      },
    ],
    logo:'https://royalasia.blob.core.windows.net/default/ProperDee_transparent.png',
    homeUrl:'/home',
    showManagementLogin:false,
    isMobileMenuOpen: false,
})
const mutations = {
  setMobileHeader(state, val) {
    state.navMenus = val.navMenus
    state.logo = val.logo
    state.homeUrl = val.homeUrl
    state.showManagementLogin = val.showManagementLogin
  },
  setMobileMenuClick(state, isMobileMenuOpen) {
    state.isMobileMenuOpen = isMobileMenuOpen
    console.log(state)
  },
  setMobileMenus(state, nav) {
    state.navMenus = nav
  },
  setMobileMenuLogo(state, logo) {
    state.logo = logo
  },
  setMobileMenuHomeUrl(state, homeUrl) {
    state.homeUrl = homeUrl
  },
  setMobileMenuManagementLoginBtn(state, showManagementLogin) {
    state.showManagementLogin = showManagementLogin
  },
  resetState(state) {
    Object.assign(state,resetState());
  },

}
export default mutations
