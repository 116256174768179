const actions = {
  async updateUserLangAndUserType({ commit }, params) {
    const response = await this.$repositories.generic.api.call({
      storedprocedure: 'updateLanguageAndUserType',
      parameters: params,
    })

    console.log('updateUserLangAndUserType', response.data)
    return response.data.ApiStatus && response.data.DbQueryStatus[0].status
  },
  async updateUserPropertyType({ commit }, params) {
    const response = await this.$repositories.generic.api.call({
      storedprocedure: 'insertUserProfileInformation_1',
      parameters: params,
    })

    console.log('updateUserPropertyType', response.data)
    return response.data.ApiStatus && response.data.DbQueryStatus[0].status
  },
  async updateUserProfile({ commit }, params) {
    const response = await this.$repositories.generic.api.call({
      storedprocedure: 'insertUserProfileInformation_2',
      parameters: params,
    })

    console.log('updateUserProfile', response.data)
    return response.data.ApiStatus && response.data.DbQueryStatus[0].status
  },
  async updateUserSubDomain({ commit }, params) {
    const response = await this.$repositories.generic.api.call({
      storedprocedure: 'updateDesiredSubdomain',
      parameters: params,
    })

    console.log('updateUserSubDomain', response.data)
    return response.data.ApiStatus && response.data.DbQueryStatus[0].status
  },
  async getUserProfile({ commit }, params) {
    const response = await this.$repositories.auth.getUser()
    console.log('getUserProfileresponse', response.data.data.user)
    if (response) {
      this.$auth.setUser(response.data.data.user)
    }
    return response.data.data.user
  },
  async setUserRole({ commit }, params) {
    const response = await this.$repositories.user.setUserRole(params)
    console.log('setUserRole', response)
    return response.data
  },
  async updateBusinessName({ commit }, params) {
    const response = await this.$repositories.user.updateBusinessName(params)
    console.log('setUserRole', response)
    return response.data
  },
  async createSubDomain({ commit }, params) {
    const response = await this.$repositories.user.createSubDomain(params)
    console.log('setUserRole', response)
    return response.data
  },
  async checkSubDomain({ commit }, params) {
    const response = await this.$repositories.user.checkSubDomain(params)
    console.log('setUserRole', response)
    return response.data
  },
  async createCompany({ commit }, params) {
    const response = await this.$repositories.user.createCompany(params)
    console.log('setUserRole', response)
    return response.data
  },
  async shareProperdee({ commit }, params) {
    const response = await this.$repositories.user.shareProperdee(params)
    return response.data
  },
  async getListOfAlerts({ commit }, params) {
    const res = await this.$repositories.user.getListOfAlerts(params)
    return res.data
  },
  async getAlertDetail({ commit }, params) {
    const res = await this.$repositories.user.getAlertDetail(params)
    return res.data
  },
  async getMyAccount({ commit }, params) {
    const res = await this.$repositories.user.getMyAccount(params)
    return res.data
  },
  async updateMyAccount({ commit }, params) {
    const res = await this.$repositories.user.updateMyAccount(params)
    return res.data
  },
  async doneProperdeeAlerts({ commit }, params) {
    const res = await this.$repositories.user.doneProperdeeAlerts(params)
    return res.data
  },
  async getTasks({ commit }, params) {
    const res = await this.$repositories.user.getTasks(params)
    return res.data
  },
  async getTaskCallender({ commit }, params) {
    const res = await this.$repositories.user.getTaskCallender(params)
    return res.data
  },
  async addTaskCallender({ commit }, params) {
    const res = await this.$repositories.user.addTaskCallender(params)
    return res.data
  },
  async deleteTaskCallender({ commit }, params) {
    const res = await this.$repositories.user.deleteTaskCallender(params)
    return res.data
  },
  async getRecentTasks({ commit }, params) {
    const res = await this.$repositories.user.getRecentTasks(params)
    return res.data
  },
  async resetCurrentUser({ commit }, params) {
    const res = await this.$repositories.user.resetUser(params)
    return res.data
  },
  async getContactDetails({ commit }, params) {
    const res = await this.$repositories.user.getContactDetails(params)
    return res.data
  },
  async updateContactDetails({ commit }, params) {
    const res = await this.$repositories.user.updateContactDetails(params)
    return res.data
  },
}
export default actions
