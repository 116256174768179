const controller = '/media'
export default axios => ({
  addMedia(obj) {
    return axios.put(`${controller}/${obj.containerId}/add`, obj.formData)
  },
  addMultipleMedia(obj) {
    return axios.put(`${controller}/${obj.containerId}/add-multiple-file`, obj.formData)
  },
  deleteMedia(obj) {
    return axios.delete(`${controller}/${obj.containerId}/delete`, {
      fileName: obj.fileName,
    })
  },
})
