const actions = {
  logout({ commit }, params) {
    commit('setUserInfo', null)
    return true
  },

  async register({ commit }, params) {
    const response = await this.$repositories.auth.register(params)
    return response.data
  },
  async forgetPasword({ commit }, params) {
    const response = await this.$repositories.auth.forgetPasword(params)
    return response.data
  },
  async resetPassword({ commit }, params) {
    const response = await this.$repositories.auth.resetPassword(params)
    return response.data
  },
  async verifyEmail({ commit }, params) {
    const response = await this.$repositories.auth.verifyEmail(params)
    return response.data
  },
  async chosePakcage({ commit }, params) {
    const response = await this.$repositories.auth.chosePakcage(params)
    return response.data
  },
}
export default actions
