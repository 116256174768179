// Locales
import th from 'vuetify/es5/locale/th'
import en from 'vuetify/es5/locale/en'
// Feather icons
import {
  UserIcon,
  UserPlusIcon,
  LogInIcon,
  LogOutIcon,
  AlertTriangleIcon,
  UploadCloudIcon,
  EditIcon,
  RotateCwIcon,
  Trash2Icon,
  GridIcon,
  ListIcon,
  TrendingUpIcon,
  MailIcon,
  MessageSquareIcon,
  RepeatIcon,
  ClockIcon,
  HeartIcon,
  SettingsIcon,
  EyeIcon,
  Volume2Icon,
  EyeOffIcon,
  DownloadIcon,
  CheckSquareIcon,
  HomeIcon,
  Edit3Icon,
  PauseIcon,
} from 'vue-feather-icons'
// Custom svg icons
import IconListPlus from '~/components/ui/icons/IconListPlus'
import IconLine from '~/components/ui/icons/IconLine'
import IconMailBulk from '~/components/ui/icons/IconMailBulk'
import IconCoin from '~/components/ui/icons/IconCoin'
import IconSun from '~/components/ui/icons/IconSun'
import IconPaypal from '~/components/ui/icons/IconPaypal'
import IconEmailRepeat from '~/components/ui/icons/IconEmailRepeat'
import IconDoubleArrowUp from '~/components/ui/icons/IconDoubleArrowUp'
import IconDollar from '~/components/ui/icons/IconDollar'
import IconLand from '~/components/ui/icons/IconLand'
import IconMotorcycle from '~/components/ui/icons/IconMotorcycle'

export default ({ req, nuxtState }) => {
  // const state = req
  const state = process.server ? req : nuxtState.state.config.site.siteConfig
  const colours = state
    ? {
        primary: state.primaryColor ? state.primaryColor : '#8dc73f',
        secondary: state.secondaryColor,
        error: '#ff0000',
        success: state.successColor ? state.successColor : '#8dc73f',
        availableColor: state.availableColor ? state.availableColor : '#8dc73f',
        comingSoonColor: state.comingSoonColor ? state.comingSoonColor : '#8dc73f',
        rentedColor: state.rentedColor ? state.rentedColor : '#8dc73f',
        // sidebar: '#171725',
        // dashboardBackground: '#f7f7f7',
      }
    : {}
  return {
    lang: {
      locales: { th, en },
    },

    theme: {
      options: {
        customProperties: true,
      },
      dark: false,
      themes: {
        light: colours,
      },
      disable: false,
    },
    breakpoint: {
      thresholds: {
        lg: 1921,
      },
      scrollBarWidth: 0,
    },
    icons: {
      values: {
        dropdown: 'mdi-chevron-down',
        listPlus: {
          component: IconListPlus,
        },
        iconLine: {
          component: IconLine,
        },
        IconMailBulk: {
          component: IconMailBulk,
        },
        user: {
          component: UserIcon,
        },
        userPlus: {
          component: UserPlusIcon,
        },
        login: {
          component: LogInIcon,
        },
        logout: {
          component: LogOutIcon,
        },
        alertTriangleIcon: {
          component: AlertTriangleIcon,
        },
        upload: {
          component: UploadCloudIcon,
        },
        editCube: {
          component: EditIcon,
        },
        rotateCW: {
          component: RotateCwIcon,
        },
        trashBin: {
          component: Trash2Icon,
        },
        coin: {
          component: IconCoin,
        },
        gridList: {
          component: GridIcon,
        },
        sun: {
          component: IconSun,
        },
        list: {
          component: ListIcon,
        },
        trendUp: {
          component: TrendingUpIcon,
        },
        mail: {
          component: MailIcon,
        },
        chat: {
          component: MessageSquareIcon,
        },
        repeat: {
          component: RepeatIcon,
        },
        clock: {
          component: ClockIcon,
        },
        heart: {
          component: HeartIcon,
        },
        settings: {
          component: SettingsIcon,
        },
        eye: {
          component: EyeIcon,
        },
        eyeOff: {
          component: EyeOffIcon,
        },
        volume: {
          component: Volume2Icon,
        },
        trash: {
          component: Trash2Icon,
        },
        download: {
          component: DownloadIcon,
        },
        paypal: {
          component: IconPaypal,
        },
        emailRepeat: {
          component: IconEmailRepeat,
        },
        doubleArrowUp: {
          component: IconDoubleArrowUp,
        },
        squareChecked: {
          component: CheckSquareIcon,
        },
        home: {
          component: HomeIcon,
        },
        edit3Icon: {
          component: Edit3Icon,
        },
        dollarIcon: {
          component: IconDollar,
        },
        pause: {
          component: PauseIcon,
        },
        land: {
          component: IconLand,
        },
        motorcycle: {
          component: IconMotorcycle,
        },
      },
    },
  }
}
