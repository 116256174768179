import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/user/index.js'), 'user/index.js')
  resolveStoreModules(require('../store/translate/index.js'), 'translate/index.js')
  resolveStoreModules(require('../store/taxonomy/index.js'), 'taxonomy/index.js')
  resolveStoreModules(require('../store/subdomain/index.js'), 'subdomain/index.js')
  resolveStoreModules(require('../store/personalWebsite/index.js'), 'personalWebsite/index.js')
  resolveStoreModules(require('../store/payment/index.js'), 'payment/index.js')
  resolveStoreModules(require('../store/packages/index.js'), 'packages/index.js')
  resolveStoreModules(require('../store/owner/index.js'), 'owner/index.js')
  resolveStoreModules(require('../store/media/index.js'), 'media/index.js')
  resolveStoreModules(require('../store/manager/index.js'), 'manager/index.js')
  resolveStoreModules(require('../store/home/index.js'), 'home/index.js')
  resolveStoreModules(require('../store/faq/index.js'), 'faq/index.js')
  resolveStoreModules(require('../store/documents/index.js'), 'documents/index.js')
  resolveStoreModules(require('../store/communication/index.js'), 'communication/index.js')
  resolveStoreModules(require('../store/chat/index.js'), 'chat/index.js')
  resolveStoreModules(require('../store/authentication/index.js'), 'authentication/index.js')
  resolveStoreModules(require('../store/ai/index.js'), 'ai/index.js')
  resolveStoreModules(require('../store/admin/index.js'), 'admin/index.js')
  resolveStoreModules(require('../store/adddemo/index.js'), 'adddemo/index.js')
  resolveStoreModules(require('../store/adddemo/actions.js'), 'adddemo/actions.js')
  resolveStoreModules(require('../store/adddemo/getters.js'), 'adddemo/getters.js')
  resolveStoreModules(require('../store/adddemo/mutations.js'), 'adddemo/mutations.js')
  resolveStoreModules(require('../store/adddemo/state.js'), 'adddemo/state.js')
  resolveStoreModules(require('../store/admin/actions.js'), 'admin/actions.js')
  resolveStoreModules(require('../store/admin/getters.js'), 'admin/getters.js')
  resolveStoreModules(require('../store/admin/mutations.js'), 'admin/mutations.js')
  resolveStoreModules(require('../store/admin/state.js'), 'admin/state.js')
  resolveStoreModules(require('../store/ai/actions.js'), 'ai/actions.js')
  resolveStoreModules(require('../store/ai/getters.js'), 'ai/getters.js')
  resolveStoreModules(require('../store/ai/mutations.js'), 'ai/mutations.js')
  resolveStoreModules(require('../store/ai/state.js'), 'ai/state.js')
  resolveStoreModules(require('../store/authentication/actions.js'), 'authentication/actions.js')
  resolveStoreModules(require('../store/authentication/getters.js'), 'authentication/getters.js')
  resolveStoreModules(require('../store/authentication/mutations.js'), 'authentication/mutations.js')
  resolveStoreModules(require('../store/authentication/state.js'), 'authentication/state.js')
  resolveStoreModules(require('../store/chat/actions.js'), 'chat/actions.js')
  resolveStoreModules(require('../store/chat/getters.js'), 'chat/getters.js')
  resolveStoreModules(require('../store/chat/mutations.js'), 'chat/mutations.js')
  resolveStoreModules(require('../store/chat/state.js'), 'chat/state.js')
  resolveStoreModules(require('../store/communication/actions.js'), 'communication/actions.js')
  resolveStoreModules(require('../store/communication/getters.js'), 'communication/getters.js')
  resolveStoreModules(require('../store/communication/mutations.js'), 'communication/mutations.js')
  resolveStoreModules(require('../store/communication/state.js'), 'communication/state.js')
  resolveStoreModules(require('../store/documents/actions.js'), 'documents/actions.js')
  resolveStoreModules(require('../store/documents/getters.js'), 'documents/getters.js')
  resolveStoreModules(require('../store/documents/mutations.js'), 'documents/mutations.js')
  resolveStoreModules(require('../store/documents/state.js'), 'documents/state.js')
  resolveStoreModules(require('../store/faq/actions.js'), 'faq/actions.js')
  resolveStoreModules(require('../store/faq/getters.js'), 'faq/getters.js')
  resolveStoreModules(require('../store/faq/mutations.js'), 'faq/mutations.js')
  resolveStoreModules(require('../store/faq/state.js'), 'faq/state.js')
  resolveStoreModules(require('../store/home/actions.js'), 'home/actions.js')
  resolveStoreModules(require('../store/home/getters.js'), 'home/getters.js')
  resolveStoreModules(require('../store/home/mutations.js'), 'home/mutations.js')
  resolveStoreModules(require('../store/home/state.js'), 'home/state.js')
  resolveStoreModules(require('../store/manager/actions.js'), 'manager/actions.js')
  resolveStoreModules(require('../store/manager/getters.js'), 'manager/getters.js')
  resolveStoreModules(require('../store/manager/mutations.js'), 'manager/mutations.js')
  resolveStoreModules(require('../store/manager/state.js'), 'manager/state.js')
  resolveStoreModules(require('../store/media/actions.js'), 'media/actions.js')
  resolveStoreModules(require('../store/media/getters.js'), 'media/getters.js')
  resolveStoreModules(require('../store/media/mutations.js'), 'media/mutations.js')
  resolveStoreModules(require('../store/media/state.js'), 'media/state.js')
  resolveStoreModules(require('../store/owner/actions.js'), 'owner/actions.js')
  resolveStoreModules(require('../store/owner/getters.js'), 'owner/getters.js')
  resolveStoreModules(require('../store/owner/mutations.js'), 'owner/mutations.js')
  resolveStoreModules(require('../store/owner/state.js'), 'owner/state.js')
  resolveStoreModules(require('../store/packages/actions.js'), 'packages/actions.js')
  resolveStoreModules(require('../store/packages/getters.js'), 'packages/getters.js')
  resolveStoreModules(require('../store/packages/mutations.js'), 'packages/mutations.js')
  resolveStoreModules(require('../store/packages/state.js'), 'packages/state.js')
  resolveStoreModules(require('../store/payment/actions.js'), 'payment/actions.js')
  resolveStoreModules(require('../store/payment/getters.js'), 'payment/getters.js')
  resolveStoreModules(require('../store/payment/mutations.js'), 'payment/mutations.js')
  resolveStoreModules(require('../store/payment/state.js'), 'payment/state.js')
  resolveStoreModules(require('../store/personalWebsite/actions.js'), 'personalWebsite/actions.js')
  resolveStoreModules(require('../store/personalWebsite/getters.js'), 'personalWebsite/getters.js')
  resolveStoreModules(require('../store/personalWebsite/mutations.js'), 'personalWebsite/mutations.js')
  resolveStoreModules(require('../store/personalWebsite/state.js'), 'personalWebsite/state.js')
  resolveStoreModules(require('../store/subdomain/actions.js'), 'subdomain/actions.js')
  resolveStoreModules(require('../store/subdomain/getters.js'), 'subdomain/getters.js')
  resolveStoreModules(require('../store/subdomain/mutations.js'), 'subdomain/mutations.js')
  resolveStoreModules(require('../store/subdomain/state.js'), 'subdomain/state.js')
  resolveStoreModules(require('../store/taxonomy/actions.js'), 'taxonomy/actions.js')
  resolveStoreModules(require('../store/taxonomy/getters.js'), 'taxonomy/getters.js')
  resolveStoreModules(require('../store/taxonomy/mutations.js'), 'taxonomy/mutations.js')
  resolveStoreModules(require('../store/taxonomy/state.js'), 'taxonomy/state.js')
  resolveStoreModules(require('../store/translate/actions.js'), 'translate/actions.js')
  resolveStoreModules(require('../store/translate/getters.js'), 'translate/getters.js')
  resolveStoreModules(require('../store/translate/mutations.js'), 'translate/mutations.js')
  resolveStoreModules(require('../store/translate/state.js'), 'translate/state.js')
  resolveStoreModules(require('../store/user/actions.js'), 'user/actions.js')
  resolveStoreModules(require('../store/user/getters.js'), 'user/getters.js')
  resolveStoreModules(require('../store/user/mutations.js'), 'user/mutations.js')
  resolveStoreModules(require('../store/user/state.js'), 'user/state.js')
  resolveStoreModules(require('../store/config/site/index.js'), 'config/site/index.js')
  resolveStoreModules(require('../store/config/menu/index.js'), 'config/menu/index.js')
  resolveStoreModules(require('../store/config/locale/index.js'), 'config/locale/index.js')
  resolveStoreModules(require('../store/config/locale/actions.js'), 'config/locale/actions.js')
  resolveStoreModules(require('../store/config/locale/getters.js'), 'config/locale/getters.js')
  resolveStoreModules(require('../store/config/locale/mutations.js'), 'config/locale/mutations.js')
  resolveStoreModules(require('../store/config/locale/state.js'), 'config/locale/state.js')
  resolveStoreModules(require('../store/config/menu/actions.js'), 'config/menu/actions.js')
  resolveStoreModules(require('../store/config/menu/getters.js'), 'config/menu/getters.js')
  resolveStoreModules(require('../store/config/menu/mutations.js'), 'config/menu/mutations.js')
  resolveStoreModules(require('../store/config/menu/state.js'), 'config/menu/state.js')
  resolveStoreModules(require('../store/config/site/actions.js'), 'config/site/actions.js')
  resolveStoreModules(require('../store/config/site/getters.js'), 'config/site/getters.js')
  resolveStoreModules(require('../store/config/site/mutations.js'), 'config/site/mutations.js')
  resolveStoreModules(require('../store/config/site/state.js'), 'config/site/state.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
