const mutations = {
  setPayment(state, payment) {
    state.payment = payment
  },

  setPaymentConfig(state, paymentConfig) {
    state.paymentConfig = paymentConfig
  },
}
export default mutations
