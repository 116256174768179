
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  props: {
    dialogClose: {
      type: Boolean,
      default: false,
    },
    showRegisterButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorDialog: false,
      errorObj: undefined,
      valid: true,
      loginButtonClicked: false,
      registerShow: false,
      show: false,
      forgetPassword: false,
      user: {
        email: null,
        password: null,
        type: null,
      },
      emailRules: [
        v => !!v || this.$t('E-mail is required'),
        v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
      ],
      passwordRules: [
        v => !!v || this.$t('Password is required'),
        v =>
          (v && v.length >= 4) ||
          this.$t('Password must be equal or greater than 8 characters'),
      ],
    }
  },
  watch: {
    isLogInDialogShowOpen(val) {
      if (!val) {
        this.registerShow = false
      }
    },
    '$auth.user.isFirstTimeLogin'(val) {
      console.log('login', this.$auth.user)
      if (this.$auth.user && this.$auth.user.isFirstTimeLogin) {
        this.$router.go('/success-register')
      }
    },
    '$auth.user.package.subscription'(val) {
      console.log('login', this.$auth.user)
      if (
        this.$auth.user &&
        this.$auth.user.package.subscription &&
        !this.$auth.user.subDomain
      ) {
        this.$router.go('/success-register')
      }else if(this.$auth.user.package.paymentUrl){
        this.$router.push(this.$auth.user.package.paymentUrl)
      }
    },
  },
  computed: {
    ...mapState('config/site', ['isLogInDialogShowOpen', 'typeOfLogin']),
  },
  methods: {
    ...mapActions('authtentication', ['login']),
    ...mapActions('user', ['getUserProfile']),
    ...mapMutations('config/site', ['setLogInDialogShow']),
    ...mapActions('config/site', ['setErrorSnackbar']),
    validate() {
      return this.$refs.loginForm.validate()
    },
    makeLogin() {
      this.loginButtonClicked = true
      if (this.validate()) {
        this.loginFunction()
      } else {
        this.loginButtonClicked = false
      }
    },
    showRegister(ev) {
      this.registerShow = ev
      this.forgetPassword = ev
    },

    loginFunction() {
      if (process.client) {
        let redirectPath = this.$auth.$storage.getUniversal('redirect')
        if (redirectPath) {
          // by auth middleware
          const queryParams = new URLSearchParams(this.$route.query).toString()
          if (queryParams) redirectPath += `?${queryParams}`
          this.$auth.$storage.setUniversal('after_login_redirect', redirectPath)
        } else {
          // by manual query param
          const redirectQueryParam = this.$route.query.redirectAfterLogin
          if (redirectQueryParam) {
            this.$auth.$storage.setUniversal(
              'after_login_redirect',
              redirectQueryParam
            )
          }
        }
      }
      console.log('this.typeOfLogin', this.typeOfLogin)
      console.log(this.user)
      this.user.type = this.typeOfLogin
      // this.$auth.loginWith('custom')
      this.$auth
        .loginWith('local', { data: this.user })
        .then(data => {
          // this.$auth
          //   .setUserToken(data.token, data.refreshToken)
          //   .then(() => this.$toast.success('User set!'))
          console.log("user - login ", data)
          console.log(
            'get refresh token',
            this.$auth.strategy.refreshToken.get()
          )
          console.log('status', this.$auth.strategy.refreshToken.status())
          // this.loginFunction()
          this.setErrorSnackbar({
            title: this.$t('Login!'),
            msg: this.$t('Successful!'),
            color: this.$vuetify.theme.themes.light.success,
          })
          this.loginButtonClicked = false
          this.setLogInDialogShow(false)
        })
        .catch((err, mess) => {
          this.loginButtonClicked = false

          console.log('err, mess', err, err.response.data)
          this.errorDialog = true
          this.errorObj = err.response
          this.setErrorSnackbar({
            title: this.$t('Login!'),
            msg: this.$t('Error! Try again!'),
            color: this.$vuetify.theme.themes.light.error,
          })
        })
    },
    fillMockup() {
      this.user.email = 'moethida@gmail.com'
      this.user.password = '123456789'
      // console.log(this.$vuetify.dev)
      // console.log(this.$vuetify.baseURL)
      // console.log(this.$dev)
    },
  },
}
