
import { mapActions } from 'vuex'
export default {
  props:{
    role:{
      type:Number,
      default:2
    }
  },
  data() {
    return {
      valid: false,
      show: false,
      step: 1,
      user: {
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        role: this.role
      },
      registerButtonClicked: false,
      firstNameRules: [v => !!v || this.$t('First Name is required')],
      lastNameRules: [v => !!v || this.$t('Last Name is required')],
      emailRules: [
        v => !!v || this.$t('E-mail is required'),
        v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
      ],
      passwordRules: [
        v => !!v || this.$t('Password is required'),
        v =>
          (v && v.length >= 8) ||
          this.$t('Password must be equal or greater than 8 characters'),
      ],
      phoneRules: [
        v => !!v || this.$t('Phone Number is required'),
        v => {
          if (v && v.length) {
            return (
              v[v.length - 1].length <= 10 ||
              this.$t('Phone number should not be longer than 10 characters')
            )
          } else return true
        },
      ],
    }
  },

  methods: {
    ...mapActions('authentication', ['register','chosePakcage']),
    ...mapActions('config/site', ['setErrorSnackbar']),
    // register
    hideRegister() {
      this.$emit('hideRegister', false)
    },
    validate() {
      return this.$refs.registerForm.validate()
    },
    clickRegister() {
      this.registerButtonClicked = true
      if (this.validate()) {
        this.register({ userInfo: this.user })
          .then(data => {
            if (data) {
              this.setErrorSnackbar({
                title: this.$t('Register!'),
                msg: this.$t('Successful!'),
                color: this.$vuetify.theme.themes.light.success,
              })
              this.step = 3
            } else {
              this.$refs.registerForm.reset()
              this.validate()
              this.setErrorSnackbar({
                title: this.$t('Register!'),
                msg: this.$t('Failed!, Please Check form!'),
                color: this.$vuetify.theme.themes.light.error,
              })
            }
            this.sendPackage()
            setTimeout(() => {
              this.registerButtonClicked = false
            }, 500)
          })
          .catch(err => {
            console.log(err)

            this.registerButtonClicked = false

            this.valid = false
            if (err&&err.response.data.message === 'Email is already used') {
              this.step = 1
              this.user.email = null
              this.validate()
            }
            this.setErrorSnackbar({
              title: this.$t('Register!'),
              msg: this.$t(err.response.data.message),
              color: this.$vuetify.theme.themes.light.error,
            })
          })
      } else {
        this.registerButtonClicked = false
      }
    },
    sendPackage(){
      const id= JSON.parse(localStorage.getItem('selectedPackage')).id
      const addons= JSON.parse(localStorage.getItem('selectedAddons'))
      this.chosePakcage({
        "email": this.user.email,
        "packageId": id,
        "addOns": addons?addons:null
      }).then(data=>{
        console.log("sendPackage",data)
      })
    },  
    mockup() {
      this.user = {
        email: 'test' + Math.floor(Math.random() * 10) + '@Properdee.com',
        password: '123456789',
        firstName: 'test' + Math.floor(Math.random() * 10),
        lastName: 'test' + Math.floor(Math.random() * 10),
        phoneNumber: '123456789',
      }
    },
  },
  destroyed() {
    console.log('register destroyed!!')
    this.hideRegister()
  },
}
