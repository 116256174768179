const controller = '/sub-domain'
export default axios => ({
  getConfig(obj) {
    return axios.get(`${controller}/config/${obj.subdomainName}`, obj)
  },
  sendPersonalWebsiteContact(obj) {
    return axios.post(`${controller}/config/${obj.subdomainName}/sendMessage`, obj)
  },
  getPageDetails(obj) {
    // let parmas = `/`
    // if(obj.pageName==='featured'){
    //     parmas=`?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}&orderBy=${obj.orderBy}`
    //   if(obj.search){
    //     parmas=parmas+`&search=${obj.search}`
    //   }
    // }
    return axios.post(`${controller}/${obj.subdomainName}/${obj.pageName}`, obj)
  },
})
