const actions = {
  
 async addOwner({ commit }, params) {
    const response = await this.$repositories.generic.api.call({
       "storedprocedure": "insertOwnerInformation",
        "parameters":params
    })
    
      console.log('addOwner',response.data)
    return response.data.ApiStatus && response.data.DbQueryStatus[0].status?response.data.DbQueryData.Table[0].ID:response.data.ApiStatus && response.data.DbQueryStatus[0].status
  },
 async addEmergencyContactsOfOwner({ commit }, params) {
    const response = await this.$repositories.generic.api.call({
       "storedprocedure": "insertOwnerEmergencyContacts",
        "parameters":params
    })
    
      console.log('addEmergencyContactsOfOwner',response.data)
    return response.data.ApiStatus && response.data.DbQueryStatus[0].status
  },
 async addOwnerMedia({ commit }, params) {
    const response = await this.$repositories.generic.api.call({
       "storedprocedure": "insertOwnerMedia",
        "parameters":params
    })
    
      console.log('addOwnerMedia',response.data)
    return response.data.ApiStatus && response.data.DbQueryStatus[0].status
  },

}
export default actions
