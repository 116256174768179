const actions = {
  
 async getPropertyDescription({ commit }, params) {
    const response = await this.$repositories.ai.getPropertyDescriptionFromAi(params)
    
    return response.data
  },
 async getPropertyValuation({ commit }, params) {
    const response = await this.$repositories.ai.getPropertyValuation(params)
    
    return response.data
  },
 async getFollowUpAnswer({ commit }, params) {
    const response = await this.$repositories.ai.getFollowUpAnswer(params)
    
    return response.data
  },
 async getSummaryAiValuation({ commit }, params) {
    const response = await this.$repositories.ai.getSummaryAiValuation(params)
    
    return response.data
  },
 async shareValuation({ commit }, params) {
    const response = await this.$repositories.ai.shareValuation(params)
    
    return response.data
  },
 async previewShare({ commit }, params) {
    const response = await this.$repositories.ai.previewShare(params)
    
    return response.data
  },
 async getTranslationForAi({ commit }, params) {
    const response = await this.$repositories.ai.getTranslationForAi(params)
    
    return response.data
  },
 async updatePropertyValuation({ commit }, params) {
    const response = await this.$repositories.ai.updatePropertyValuation(params)
    
    return response.data
  },
 async getEmailForAi({ commit }, params) {
    const response = await this.$repositories.ai.getEmailForAi(params)
    
    return response.data
  },

}
export default actions
