export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const AddOwner = () => import('../../components/AddOwner.vue' /* webpackChunkName: "components/add-owner" */).then(c => wrapFunctional(c.default || c))
export const AddTenant = () => import('../../components/AddTenant.vue' /* webpackChunkName: "components/add-tenant" */).then(c => wrapFunctional(c.default || c))
export const AccountingAddIncomeAccount = () => import('../../components/accounting/AddIncomeAccount.vue' /* webpackChunkName: "components/accounting-add-income-account" */).then(c => wrapFunctional(c.default || c))
export const AccountingAllOtherIncome = () => import('../../components/accounting/AllOtherIncome.vue' /* webpackChunkName: "components/accounting-all-other-income" */).then(c => wrapFunctional(c.default || c))
export const AccountingBillPaidSummary = () => import('../../components/accounting/BillPaidSummary.vue' /* webpackChunkName: "components/accounting-bill-paid-summary" */).then(c => wrapFunctional(c.default || c))
export const AccountingCashFlow = () => import('../../components/accounting/CashFlow.vue' /* webpackChunkName: "components/accounting-cash-flow" */).then(c => wrapFunctional(c.default || c))
export const AccountingChartOfAccounts = () => import('../../components/accounting/ChartOfAccounts.vue' /* webpackChunkName: "components/accounting-chart-of-accounts" */).then(c => wrapFunctional(c.default || c))
export const AccountingOwnerStatement = () => import('../../components/accounting/OwnerStatement.vue' /* webpackChunkName: "components/accounting-owner-statement" */).then(c => wrapFunctional(c.default || c))
export const AccountingRecordBill = () => import('../../components/accounting/RecordBill.vue' /* webpackChunkName: "components/accounting-record-bill" */).then(c => wrapFunctional(c.default || c))
export const AccountingRentRoll = () => import('../../components/accounting/RentRoll.vue' /* webpackChunkName: "components/accounting-rent-roll" */).then(c => wrapFunctional(c.default || c))
export const AccountingTenantPayment = () => import('../../components/accounting/TenantPayment.vue' /* webpackChunkName: "components/accounting-tenant-payment" */).then(c => wrapFunctional(c.default || c))
export const AccountingTenantStatement = () => import('../../components/accounting/TenantStatement.vue' /* webpackChunkName: "components/accounting-tenant-statement" */).then(c => wrapFunctional(c.default || c))
export const AgentCard = () => import('../../components/agent/card.vue' /* webpackChunkName: "components/agent-card" */).then(c => wrapFunctional(c.default || c))
export const Contact = () => import('../../components/contact/index.vue' /* webpackChunkName: "components/contact" */).then(c => wrapFunctional(c.default || c))
export const Dashboard = () => import('../../components/dashboard/index.vue' /* webpackChunkName: "components/dashboard" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDraftALease = () => import('../../components/documents/DraftALease.vue' /* webpackChunkName: "components/documents-draft-a-lease" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDraftAPropertyInspectionBk = () => import('../../components/documents/DraftAPropertyInspection-bk.vue' /* webpackChunkName: "components/documents-draft-a-property-inspection-bk" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDraftAPropertyInspection = () => import('../../components/documents/DraftAPropertyInspection.vue' /* webpackChunkName: "components/documents-draft-a-property-inspection" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDraftAPropertyManagementFee = () => import('../../components/documents/DraftAPropertyManagementFee.vue' /* webpackChunkName: "components/documents-draft-a-property-management-fee" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDraftMoveInOut = () => import('../../components/documents/DraftMoveInOut.vue' /* webpackChunkName: "components/documents-draft-move-in-out" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDraftSecurityDeposit = () => import('../../components/documents/DraftSecurityDeposit.vue' /* webpackChunkName: "components/documents-draft-security-deposit" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDraftTenantWelcomeLetter = () => import('../../components/documents/DraftTenantWelcomeLetter.vue' /* webpackChunkName: "components/documents-draft-tenant-welcome-letter" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterContactUs = () => import('../../components/helpCenter/ContactUs.vue' /* webpackChunkName: "components/help-center-contact-us" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterDoNotSell = () => import('../../components/helpCenter/DoNotSell.vue' /* webpackChunkName: "components/help-center-do-not-sell" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterFaq = () => import('../../components/helpCenter/Faq.vue' /* webpackChunkName: "components/help-center-faq" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterPolicy = () => import('../../components/helpCenter/Policy.vue' /* webpackChunkName: "components/help-center-policy" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterTermsAndCondition = () => import('../../components/helpCenter/TermsAndCondition.vue' /* webpackChunkName: "components/help-center-terms-and-condition" */).then(c => wrapFunctional(c.default || c))
export const ListingApplyToRent = () => import('../../components/listing/ApplyToRent.vue' /* webpackChunkName: "components/listing-apply-to-rent" */).then(c => wrapFunctional(c.default || c))
export const ListingCardBk = () => import('../../components/listing/Card-bk.vue' /* webpackChunkName: "components/listing-card-bk" */).then(c => wrapFunctional(c.default || c))
export const ListingCard = () => import('../../components/listing/Card.vue' /* webpackChunkName: "components/listing-card" */).then(c => wrapFunctional(c.default || c))
export const ListingPreview = () => import('../../components/listing/Preview.vue' /* webpackChunkName: "components/listing-preview" */).then(c => wrapFunctional(c.default || c))
export const PaymentCounterPay = () => import('../../components/payment/PaymentCounterPay.vue' /* webpackChunkName: "components/payment-counter-pay" */).then(c => wrapFunctional(c.default || c))
export const PaymentCreditCard = () => import('../../components/payment/PaymentCreditCard.vue' /* webpackChunkName: "components/payment-credit-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentDigitalPay = () => import('../../components/payment/PaymentDigitalPay.vue' /* webpackChunkName: "components/payment-digital-pay" */).then(c => wrapFunctional(c.default || c))
export const PaymentInternetBanking = () => import('../../components/payment/PaymentInternetBanking.vue' /* webpackChunkName: "components/payment-internet-banking" */).then(c => wrapFunctional(c.default || c))
export const PaymentPayPal = () => import('../../components/payment/PaymentPayPal.vue' /* webpackChunkName: "components/payment-pay-pal" */).then(c => wrapFunctional(c.default || c))
export const PaymentPromptPay = () => import('../../components/payment/PaymentPromptPay.vue' /* webpackChunkName: "components/payment-prompt-pay" */).then(c => wrapFunctional(c.default || c))
export const PaymentTrueMoney = () => import('../../components/payment/PaymentTrueMoney.vue' /* webpackChunkName: "components/payment-true-money" */).then(c => wrapFunctional(c.default || c))
export const PaymentSuccess = () => import('../../components/payment/Success.vue' /* webpackChunkName: "components/payment-success" */).then(c => wrapFunctional(c.default || c))
export const PaymentFree = () => import('../../components/payment/free.vue' /* webpackChunkName: "components/payment-free" */).then(c => wrapFunctional(c.default || c))
export const PaymentGateway = () => import('../../components/payment/gateway.vue' /* webpackChunkName: "components/payment-gateway" */).then(c => wrapFunctional(c.default || c))
export const PaymentBkCreditCard = () => import('../../components/payment_bk/CreditCard.vue' /* webpackChunkName: "components/payment-bk-credit-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentBkOnlineBanking = () => import('../../components/payment_bk/OnlineBanking.vue' /* webpackChunkName: "components/payment-bk-online-banking" */).then(c => wrapFunctional(c.default || c))
export const PaymentBkPaypal = () => import('../../components/payment_bk/Paypal.vue' /* webpackChunkName: "components/payment-bk-paypal" */).then(c => wrapFunctional(c.default || c))
export const PropertyManagementHeader = () => import('../../components/propertyManagement/Header.vue' /* webpackChunkName: "components/property-management-header" */).then(c => wrapFunctional(c.default || c))
export const TenantsCard = () => import('../../components/tenants/card.vue' /* webpackChunkName: "components/tenants-card" */).then(c => wrapFunctional(c.default || c))
export const UiImageUpload = () => import('../../components/ui/ImageUpload.vue' /* webpackChunkName: "components/ui-image-upload" */).then(c => wrapFunctional(c.default || c))
export const UiUpload = () => import('../../components/ui/Upload.vue' /* webpackChunkName: "components/ui-upload" */).then(c => wrapFunctional(c.default || c))
export const AuthForgetPassword = () => import('../../components/auth/forget-password/index.vue' /* webpackChunkName: "components/auth-forget-password" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../../components/auth/login/index.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthRegister = () => import('../../components/auth/register/index.vue' /* webpackChunkName: "components/auth-register" */).then(c => wrapFunctional(c.default || c))
export const AuthResetPassword = () => import('../../components/auth/reset-password/index.vue' /* webpackChunkName: "components/auth-reset-password" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminAnalytics = () => import('../../components/dashboard/admin/Analytics.vue' /* webpackChunkName: "components/dashboard-admin-analytics" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminContacts = () => import('../../components/dashboard/admin/Contacts.vue' /* webpackChunkName: "components/dashboard-admin-contacts" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminDiscountLists = () => import('../../components/dashboard/admin/DiscountLists.vue' /* webpackChunkName: "components/dashboard-admin-discount-lists" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminDocuments = () => import('../../components/dashboard/admin/Documents.vue' /* webpackChunkName: "components/dashboard-admin-documents" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminEmailsPopups = () => import('../../components/dashboard/admin/EmailsPopups.vue' /* webpackChunkName: "components/dashboard-admin-emails-popups" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminGeneralSettings = () => import('../../components/dashboard/admin/GeneralSettings.vue' /* webpackChunkName: "components/dashboard-admin-general-settings" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminHolidayGreetingsLists = () => import('../../components/dashboard/admin/HolidayGreetingsLists.vue' /* webpackChunkName: "components/dashboard-admin-holiday-greetings-lists" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminListAdmin = () => import('../../components/dashboard/admin/ListAdmin.vue' /* webpackChunkName: "components/dashboard-admin-list-admin" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminMain = () => import('../../components/dashboard/admin/Main.vue' /* webpackChunkName: "components/dashboard-admin-main" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminManageUsers = () => import('../../components/dashboard/admin/ManageUsers.vue' /* webpackChunkName: "components/dashboard-admin-manage-users" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminMessages = () => import('../../components/dashboard/admin/Messages.vue' /* webpackChunkName: "components/dashboard-admin-messages" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminPaymentHistory = () => import('../../components/dashboard/admin/PaymentHistory.vue' /* webpackChunkName: "components/dashboard-admin-payment-history" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminProperllyAlerts = () => import('../../components/dashboard/admin/ProperllyAlerts.vue' /* webpackChunkName: "components/dashboard-admin-properlly-alerts" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminProperties = () => import('../../components/dashboard/admin/Properties.vue' /* webpackChunkName: "components/dashboard-admin-properties" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminServiceProviders = () => import('../../components/dashboard/admin/ServiceProviders.vue' /* webpackChunkName: "components/dashboard-admin-service-providers" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminSubscribersList = () => import('../../components/dashboard/admin/SubscribersList.vue' /* webpackChunkName: "components/dashboard-admin-subscribers-list" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminTemplates = () => import('../../components/dashboard/admin/Templates.vue' /* webpackChunkName: "components/dashboard-admin-templates" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminTenants = () => import('../../components/dashboard/admin/Tenants.vue' /* webpackChunkName: "components/dashboard-admin-tenants" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminUser = () => import('../../components/dashboard/admin/User.vue' /* webpackChunkName: "components/dashboard-admin-user" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminVideos = () => import('../../components/dashboard/admin/Videos.vue' /* webpackChunkName: "components/dashboard-admin-videos" */).then(c => wrapFunctional(c.default || c))
export const DashboardBahtsoldMain = () => import('../../components/dashboard/bahtsold/Main.vue' /* webpackChunkName: "components/dashboard-bahtsold-main" */).then(c => wrapFunctional(c.default || c))
export const DashboardBahtsoldProperties = () => import('../../components/dashboard/bahtsold/Properties.vue' /* webpackChunkName: "components/dashboard-bahtsold-properties" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerAccounting = () => import('../../components/dashboard/manager/Accounting.vue' /* webpackChunkName: "components/dashboard-manager-accounting" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerAddDocument = () => import('../../components/dashboard/manager/AddDocument.vue' /* webpackChunkName: "components/dashboard-manager-add-document" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerAttachment = () => import('../../components/dashboard/manager/Attachment.vue' /* webpackChunkName: "components/dashboard-manager-attachment" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerBusinessCard = () => import('../../components/dashboard/manager/BusinessCard.vue' /* webpackChunkName: "components/dashboard-manager-business-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerContacts = () => import('../../components/dashboard/manager/Contacts.vue' /* webpackChunkName: "components/dashboard-manager-contacts" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerDocuments = () => import('../../components/dashboard/manager/Documents.vue' /* webpackChunkName: "components/dashboard-manager-documents" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerGeneralSettings = () => import('../../components/dashboard/manager/GeneralSettings.vue' /* webpackChunkName: "components/dashboard-manager-general-settings" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerHolidayGreetings = () => import('../../components/dashboard/manager/HolidayGreetings.vue' /* webpackChunkName: "components/dashboard-manager-holiday-greetings" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerInsurance = () => import('../../components/dashboard/manager/Insurance.vue' /* webpackChunkName: "components/dashboard-manager-insurance" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerMain = () => import('../../components/dashboard/manager/Main.vue' /* webpackChunkName: "components/dashboard-manager-main" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerMaintenance = () => import('../../components/dashboard/manager/Maintenance.vue' /* webpackChunkName: "components/dashboard-manager-maintenance" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerMarketing = () => import('../../components/dashboard/manager/Marketing.vue' /* webpackChunkName: "components/dashboard-manager-marketing" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerMessages = () => import('../../components/dashboard/manager/Messages.vue' /* webpackChunkName: "components/dashboard-manager-messages" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerMyWebsite = () => import('../../components/dashboard/manager/My-Website.vue' /* webpackChunkName: "components/dashboard-manager-my-website" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerOwners = () => import('../../components/dashboard/manager/Owners.vue' /* webpackChunkName: "components/dashboard-manager-owners" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerProperdeeAlerts = () => import('../../components/dashboard/manager/ProperdeeAlerts.vue' /* webpackChunkName: "components/dashboard-manager-properdee-alerts" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerProperties = () => import('../../components/dashboard/manager/Properties.vue' /* webpackChunkName: "components/dashboard-manager-properties" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerReport = () => import('../../components/dashboard/manager/Report.vue' /* webpackChunkName: "components/dashboard-manager-report" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerReports = () => import('../../components/dashboard/manager/Reports.vue' /* webpackChunkName: "components/dashboard-manager-reports" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerServiceProviders = () => import('../../components/dashboard/manager/ServiceProviders.vue' /* webpackChunkName: "components/dashboard-manager-service-providers" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerTemplates = () => import('../../components/dashboard/manager/Templates.vue' /* webpackChunkName: "components/dashboard-manager-templates" */).then(c => wrapFunctional(c.default || c))
export const DashboardManagerTenants = () => import('../../components/dashboard/manager/Tenants.vue' /* webpackChunkName: "components/dashboard-manager-tenants" */).then(c => wrapFunctional(c.default || c))
export const DashboardOthersMain = () => import('../../components/dashboard/others/Main.vue' /* webpackChunkName: "components/dashboard-others-main" */).then(c => wrapFunctional(c.default || c))
export const DashboardOwnerAccounting = () => import('../../components/dashboard/owner/Accounting.vue' /* webpackChunkName: "components/dashboard-owner-accounting" */).then(c => wrapFunctional(c.default || c))
export const DashboardOwnerDocuments = () => import('../../components/dashboard/owner/Documents.vue' /* webpackChunkName: "components/dashboard-owner-documents" */).then(c => wrapFunctional(c.default || c))
export const DashboardOwnerGeneralSettings = () => import('../../components/dashboard/owner/GeneralSettings.vue' /* webpackChunkName: "components/dashboard-owner-general-settings" */).then(c => wrapFunctional(c.default || c))
export const DashboardOwnerInsurance = () => import('../../components/dashboard/owner/Insurance.vue' /* webpackChunkName: "components/dashboard-owner-insurance" */).then(c => wrapFunctional(c.default || c))
export const DashboardOwnerMain = () => import('../../components/dashboard/owner/Main.vue' /* webpackChunkName: "components/dashboard-owner-main" */).then(c => wrapFunctional(c.default || c))
export const DashboardOwnerMessages = () => import('../../components/dashboard/owner/Messages.vue' /* webpackChunkName: "components/dashboard-owner-messages" */).then(c => wrapFunctional(c.default || c))
export const DashboardOwnerPayBillOnline = () => import('../../components/dashboard/owner/PayBillOnline.vue' /* webpackChunkName: "components/dashboard-owner-pay-bill-online" */).then(c => wrapFunctional(c.default || c))
export const DashboardOwnerTenants = () => import('../../components/dashboard/owner/Tenants.vue' /* webpackChunkName: "components/dashboard-owner-tenants" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedAppBarItem = () => import('../../components/dashboard/shared/AppBarItem.vue' /* webpackChunkName: "components/dashboard-shared-app-bar-item" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedContactDetails = () => import('../../components/dashboard/shared/ContactDetails.vue' /* webpackChunkName: "components/dashboard-shared-contact-details" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedFinishingDialogWithButtons = () => import('../../components/dashboard/shared/FinishingDialogWithButtons.vue' /* webpackChunkName: "components/dashboard-shared-finishing-dialog-with-buttons" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedFirstContainer = () => import('../../components/dashboard/shared/FirstContainer.vue' /* webpackChunkName: "components/dashboard-shared-first-container" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedInsurance = () => import('../../components/dashboard/shared/Insurance.vue' /* webpackChunkName: "components/dashboard-shared-insurance" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedMainDashboard = () => import('../../components/dashboard/shared/MainDashboard.vue' /* webpackChunkName: "components/dashboard-shared-main-dashboard" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedMessage = () => import('../../components/dashboard/shared/Message.vue' /* webpackChunkName: "components/dashboard-shared-message" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedNav = () => import('../../components/dashboard/shared/Nav.vue' /* webpackChunkName: "components/dashboard-shared-nav" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedNotifications = () => import('../../components/dashboard/shared/Notifications.vue' /* webpackChunkName: "components/dashboard-shared-notifications" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedReportSummary = () => import('../../components/dashboard/shared/ReportSummary.vue' /* webpackChunkName: "components/dashboard-shared-report-summary" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedTenantDetails = () => import('../../components/dashboard/shared/TenantDetails.vue' /* webpackChunkName: "components/dashboard-shared-tenant-details" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedVerification = () => import('../../components/dashboard/shared/Verification.vue' /* webpackChunkName: "components/dashboard-shared-verification" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharedWebsiteSetting = () => import('../../components/dashboard/shared/WebsiteSetting.vue' /* webpackChunkName: "components/dashboard-shared-website-setting" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantAccounting = () => import('../../components/dashboard/tenant/Accounting.vue' /* webpackChunkName: "components/dashboard-tenant-accounting" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantApplyToRent = () => import('../../components/dashboard/tenant/ApplyToRent.vue' /* webpackChunkName: "components/dashboard-tenant-apply-to-rent" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantDocuments = () => import('../../components/dashboard/tenant/Documents.vue' /* webpackChunkName: "components/dashboard-tenant-documents" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantGeneralSettings = () => import('../../components/dashboard/tenant/GeneralSettings.vue' /* webpackChunkName: "components/dashboard-tenant-general-settings" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantInsurance = () => import('../../components/dashboard/tenant/Insurance.vue' /* webpackChunkName: "components/dashboard-tenant-insurance" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantInvoice = () => import('../../components/dashboard/tenant/Invoice.vue' /* webpackChunkName: "components/dashboard-tenant-invoice" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantMain = () => import('../../components/dashboard/tenant/Main.vue' /* webpackChunkName: "components/dashboard-tenant-main" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantMaintenance = () => import('../../components/dashboard/tenant/Maintenance.vue' /* webpackChunkName: "components/dashboard-tenant-maintenance" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantMessages = () => import('../../components/dashboard/tenant/Messages.vue' /* webpackChunkName: "components/dashboard-tenant-messages" */).then(c => wrapFunctional(c.default || c))
export const DashboardTenantPayRentOnline = () => import('../../components/dashboard/tenant/PayRentOnline.vue' /* webpackChunkName: "components/dashboard-tenant-pay-rent-online" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethods = () => import('../../components/payment/desktop/PaymentMethods.vue' /* webpackChunkName: "components/payment-methods" */).then(c => wrapFunctional(c.default || c))
export const PaymentOther = () => import('../../components/payment/desktop/PaymentOther.vue' /* webpackChunkName: "components/payment-other" */).then(c => wrapFunctional(c.default || c))
export const PaymentMobileMPay = () => import('../../components/payment/mobile/MPay.vue' /* webpackChunkName: "components/payment-mobile-m-pay" */).then(c => wrapFunctional(c.default || c))
export const PaymentMobileMPaymentMethods = () => import('../../components/payment/mobile/MPaymentMethods.vue' /* webpackChunkName: "components/payment-mobile-m-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const PaymentButton = () => import('../../components/payment/shared/PaymentButton.vue' /* webpackChunkName: "components/payment-button" */).then(c => wrapFunctional(c.default || c))
export const PaymentBkOthersCounterBill = () => import('../../components/payment_bk/others/CounterBill.vue' /* webpackChunkName: "components/payment-bk-others-counter-bill" */).then(c => wrapFunctional(c.default || c))
export const PaymentBkOthersPromptPay = () => import('../../components/payment_bk/others/PromptPay.vue' /* webpackChunkName: "components/payment-bk-others-prompt-pay" */).then(c => wrapFunctional(c.default || c))
export const PaymentBkOthersTrueMoney = () => import('../../components/payment_bk/others/TrueMoney.vue' /* webpackChunkName: "components/payment-bk-others-true-money" */).then(c => wrapFunctional(c.default || c))
export const PaymentBkOthers = () => import('../../components/payment_bk/others/index.vue' /* webpackChunkName: "components/payment-bk-others" */).then(c => wrapFunctional(c.default || c))
export const PaymentBkSharedPayBtn = () => import('../../components/payment_bk/shared/PayBtn.vue' /* webpackChunkName: "components/payment-bk-shared-pay-btn" */).then(c => wrapFunctional(c.default || c))
export const UiBaseMenu = () => import('../../components/ui/base/Menu.vue' /* webpackChunkName: "components/ui-base-menu" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconCoin = () => import('../../components/ui/icons/IconCoin.vue' /* webpackChunkName: "components/ui-icons-icon-coin" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconDollar = () => import('../../components/ui/icons/IconDollar.vue' /* webpackChunkName: "components/ui-icons-icon-dollar" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconDoubleArrowUp = () => import('../../components/ui/icons/IconDoubleArrowUp.vue' /* webpackChunkName: "components/ui-icons-icon-double-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconEmailRepeat = () => import('../../components/ui/icons/IconEmailRepeat.vue' /* webpackChunkName: "components/ui-icons-icon-email-repeat" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconLand = () => import('../../components/ui/icons/IconLand.vue' /* webpackChunkName: "components/ui-icons-icon-land" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconLine = () => import('../../components/ui/icons/IconLine.vue' /* webpackChunkName: "components/ui-icons-icon-line" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconListPlus = () => import('../../components/ui/icons/IconListPlus.vue' /* webpackChunkName: "components/ui-icons-icon-list-plus" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconMailBulk = () => import('../../components/ui/icons/IconMailBulk.vue' /* webpackChunkName: "components/ui-icons-icon-mail-bulk" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconMotorcycle = () => import('../../components/ui/icons/IconMotorcycle.vue' /* webpackChunkName: "components/ui-icons-icon-motorcycle" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconPaypal = () => import('../../components/ui/icons/IconPaypal.vue' /* webpackChunkName: "components/ui-icons-icon-paypal" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconSun = () => import('../../components/ui/icons/IconSun.vue' /* webpackChunkName: "components/ui-icons-icon-sun" */).then(c => wrapFunctional(c.default || c))
export const UiSharedCarousel = () => import('../../components/ui/shared/Carousel.vue' /* webpackChunkName: "components/ui-shared-carousel" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDataTable = () => import('../../components/ui/shared/DataTable.vue' /* webpackChunkName: "components/ui-shared-data-table" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDatePicker = () => import('../../components/ui/shared/DatePicker.vue' /* webpackChunkName: "components/ui-shared-date-picker" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFooter = () => import('../../components/ui/shared/Footer.vue' /* webpackChunkName: "components/ui-shared-footer" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFooterManager = () => import('../../components/ui/shared/FooterManager.vue' /* webpackChunkName: "components/ui-shared-footer-manager" */).then(c => wrapFunctional(c.default || c))
export const UiSharedGreetings = () => import('../../components/ui/shared/Greetings.vue' /* webpackChunkName: "components/ui-shared-greetings" */).then(c => wrapFunctional(c.default || c))
export const UiSharedHomeHeader = () => import('../../components/ui/shared/HomeHeader.vue' /* webpackChunkName: "components/ui-shared-home-header" */).then(c => wrapFunctional(c.default || c))
export const UiSharedIconText = () => import('../../components/ui/shared/IconText.vue' /* webpackChunkName: "components/ui-shared-icon-text" */).then(c => wrapFunctional(c.default || c))
export const UiSharedLoadingOverlay = () => import('../../components/ui/shared/LoadingOverlay.vue' /* webpackChunkName: "components/ui-shared-loading-overlay" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMobileHeader = () => import('../../components/ui/shared/MobileHeader.vue' /* webpackChunkName: "components/ui-shared-mobile-header" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMobileMenu = () => import('../../components/ui/shared/MobileMenu.vue' /* webpackChunkName: "components/ui-shared-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const UiSharedPaginations = () => import('../../components/ui/shared/Paginations.vue' /* webpackChunkName: "components/ui-shared-paginations" */).then(c => wrapFunctional(c.default || c))
export const UiSharedLang = () => import('../../components/ui/shared/lang.vue' /* webpackChunkName: "components/ui-shared-lang" */).then(c => wrapFunctional(c.default || c))
export const UiSharedSlider = () => import('../../components/ui/shared/slider.vue' /* webpackChunkName: "components/ui-shared-slider" */).then(c => wrapFunctional(c.default || c))
export const UiSharedSmiley = () => import('../../components/ui/shared/smiley.vue' /* webpackChunkName: "components/ui-shared-smiley" */).then(c => wrapFunctional(c.default || c))
export const AuthRegisterSteps = () => import('../../components/auth/register/_steps/2.vue' /* webpackChunkName: "components/auth-register-steps" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsConfirm = () => import('../../components/ui/shared/dialogs/Confirm.vue' /* webpackChunkName: "components/ui-shared-dialogs-confirm" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsDialogMBlur = () => import('../../components/ui/shared/dialogs/DialogMBlur.vue' /* webpackChunkName: "components/ui-shared-dialogs-dialog-m-blur" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsDialogMFullPage = () => import('../../components/ui/shared/dialogs/DialogMFullPage.vue' /* webpackChunkName: "components/ui-shared-dialogs-dialog-m-full-page" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsLogin = () => import('../../components/ui/shared/dialogs/Login.vue' /* webpackChunkName: "components/ui-shared-dialogs-login" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsScheduleDialog = () => import('../../components/ui/shared/dialogs/ScheduleDialog.vue' /* webpackChunkName: "components/ui-shared-dialogs-schedule-dialog" */).then(c => wrapFunctional(c.default || c))
export const UiSharedErrorsErrorDialog = () => import('../../components/ui/shared/errors/ErrorDialog.vue' /* webpackChunkName: "components/ui-shared-errors-error-dialog" */).then(c => wrapFunctional(c.default || c))
export const UiSharedErrorsErrorMessage = () => import('../../components/ui/shared/errors/ErrorMessage.vue' /* webpackChunkName: "components/ui-shared-errors-error-message" */).then(c => wrapFunctional(c.default || c))
export const UiSharedErrorsErrorToast = () => import('../../components/ui/shared/errors/ErrorToast.vue' /* webpackChunkName: "components/ui-shared-errors-error-toast" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFileUploaderAdd = () => import('../../components/ui/shared/fileUploader/Add.vue' /* webpackChunkName: "components/ui-shared-file-uploader-add" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFileUploaderImgEditor = () => import('../../components/ui/shared/fileUploader/ImgEditor.vue' /* webpackChunkName: "components/ui-shared-file-uploader-img-editor" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFileUploader = () => import('../../components/ui/shared/fileUploader/index.vue' /* webpackChunkName: "components/ui-shared-file-uploader" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMapArea = () => import('../../components/ui/shared/map/Area.vue' /* webpackChunkName: "components/ui-shared-map-area" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMapCustomMarker = () => import('../../components/ui/shared/map/CustomMarker.vue' /* webpackChunkName: "components/ui-shared-map-custom-marker" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMapGoogle = () => import('../../components/ui/shared/map/Google.vue' /* webpackChunkName: "components/ui-shared-map-google" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
